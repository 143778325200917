<template>
    <div id="pageHeaders" class="clearMe pa-2 text-start" v-if="showHeader">
        <!-- <img class="imglogo" style="" :src="`${publicPath}/header-logo-bg.png`"> -->
        <div class="gbackground m-2 mt-0" style="display:none;padding-top:10px !important;">
               &nbsp; {{ sitetitle }}
               <span class="logoutclass">
                   <i @click="$cookies.remove($COOKIEPhase),$cookies.remove($store.state.COOKIEPhase),cook = 'null', $router.push({name: 'Logout'})" class="fas fa-sign-out-alt" style="font-size:1.7em;cursor:pointer;"></i>
               </span>
        </div>
            <v-card
                class="d-flex  text-center"
                color="#d7b100"
                >
                    <v-card
                        v-for="(item,index) in TopMenu"
                        :key="index"
                        class="pa-1"
                        style="margin-inline-end:5px;padding:25px 1px !important; border:1px solid #000"
                        outlined
                        :style="`background:`+item.bgcolor+` !important;font-weight:bold;width:130px;color:`+item.color"
                        @click="changeMenu(item.current,item.to)"
                    >
                        {{item.text}}
                    </v-card>
                    <v-card
                        class="pa-1"
                        style="margin-inline-end:5px;padding:25px 1px !important;"
                        outlined
                        :style="`background:red !important;font-weight:bold;width:130px;color:#FFF`"
                        @click="gotoHome(), $router.push({path: '/logout'})"
                    >
                        {{ lang.logout }}
                    </v-card>
            </v-card>
        
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
           sitetitle: ' ',
        //    currentmenu: 1,
           cook: '',
           publicPath: process.env.BASE_URL+'img/',

        }
    },
    created() {
        if(this.showHeader){
            this.getSettings();
            this.getUser();
        }
        setInterval(() => {
            this.cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        },500);
        this.changeMenu(this.$cookies.get(this.$store.state.currentMenu),this.$route.path);
        setInterval(() => {
            // console.log(this.$cookies.get(this.$store.state.currentMenu));
            if(!this.showHeader){
                this.$router.push({path: '/logout'})
            }
        },1000);

    },
    methods: {
        gotoHome(){
            this.$cookies.set(this.$store.state.currentMenu,1)
        },
        changeMenu(current,to){
            if(this.$route.path == to){
                return false;
            }
            if(current == ''){
                current = 1;
            }
            this.$cookies.set(this.$store.state.currentMenu,current);
            // this.currentmenu = current
            if(to != '')
                this.$router.push({path: to});
            
            this.$cookies.set(this.$store.state.currentMenu,current);
        },
        getSettings(){
            const post = new FormData();
            post.append("type" , "getSettings");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                
                this.$store.state.settings = res.results.data;
                //this.sitetitle = res.results.data.site_title;
            })
        },
        getUser(){
            const post = new FormData();
            post.append("type" , "getUser");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    this.$store.state.group_id = res.results.data.group_id;
                }
            })
        }
    },
    computed: {
        defmenu(){
            // 1 for ecar
            // 5 for invoice
            // 3 for resturant 
            // 4 for retail
            // 12 for clientlist
            // 2 for customerlist
            return this.$store.state.headerIcone;
        },
        currentmenu: function(){
            if(this.$route.path == '/' || this.$route.path == '/home'){
                this.$cookies.set(this.$store.state.currentMenu,this.defmenu);
                // currentmenu = this.defmenu;
                return this.defmenu;
            }
            if(String(this.$cookies.get(this.$store.state.currentMenu)) == 'null' || this.$route.path == '/'){
                this.$cookies.set(this.$store.state.currentMenu,this.defmenu);
            }
            return this.$cookies.get(this.$store.state.currentMenu);
        },
        lang: {
            get: function(){
                if(this.$cookies.get(this.$store.state.LangCooki) != null){
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set: function(newValue){
                return newValue
            }
        },
        showHeader: function(){
            
            if(String(this.cook) != 'null'){
                return true;
            }else{
                return false;
            }
        },
        TopMenu: function(){
            let men = [];
            let gcolor = '#000';
            let fcolor = '#fff';
            let currentmenu = this.currentmenu;
            
            if(this.$store.state.licenseType.cars){
                if(currentmenu == 1){
                    gcolor = '#fff';
                    fcolor = "#000"
                }
                if(this.lang.langname=="ar")
                    men.push({
                        text: this.$store.state.hometitle,
                        to: '/home',
                        color: fcolor, 
                        bgcolor: gcolor,
                        current: 1
                    })
                else
                    men.push({
                        text: this.$store.state.hometitle_en,
                        to: '/home',
                        color: fcolor, 
                        bgcolor: gcolor,
                        current: 1
                    })
            }
            if(this.$store.state.licenseType.crm){
                gcolor = '#000';
                fcolor = '#fff';
                if(currentmenu == 2){
                    gcolor = '#fff';
                    fcolor = "#000"
                }
                men.push({
                    text: this.lang.projects, to: "/customers",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 2
                })
            }
            if(this.$store.state.licenseType.POSREST){
                 gcolor = '#000';
                fcolor = '#fff';
                if(currentmenu == 3){
                    gcolor = '#fff';
                    fcolor = "#000"
                }
                men.push({
                    text: this.lang.pos, to: "/SAMPOS",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 3
                })
            }
            if(this.$store.state.licenseType.POSRET){
                 gcolor = '#000';
                fcolor = '#fff';
                if(currentmenu == 4){
                    gcolor = '#fff';
                    fcolor = "#000"
                }
                men.push({
                    text: this.lang.pos, to: "/POS-RETAIL",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 4
                })
            }
            // if(currentmenu == 5){
            //     gcolor = '#fff';
            //     fcolor = "#000"
            // }else{
            //      gcolor = '#000';
            //     fcolor = '#fff';
            // }
            // men.push({
            //     text: this.lang.invoices, to: "/invoicelist",
            //     color: fcolor, 
            //         bgcolor: gcolor,
            //         current: 5
            // })
            if(!this.$store.state.licenseType.crm){
                if(currentmenu == 12){
                    gcolor = '#fff';
                    fcolor = "#000"
                }else{
                    gcolor = '#000';
                    fcolor = '#fff';
                }
                men.push({
                    text: this.lang.sales_crm, to: "/clientList",
                    color: fcolor, 
                        bgcolor: gcolor,
                        current: 12
                })
            }
            if(this.groupid == 3 && this.$store.state.licenseType.agentPurchase){
                if(currentmenu == 6){
                    gcolor = '#fff';
                    fcolor = "#000"
                }else{
                     gcolor = '#000';
                    fcolor = '#fff';
                }
                men.push({
                    text: this.lang.purchase, to: "/inventory/vendors",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 6
                })
            }

            if(currentmenu == 7){
                gcolor = '#fff';
                fcolor = "#000"
            }else{
                 gcolor = '#000';
                fcolor = '#fff';
            }
            if(this.groupid == 1 || this.groupid == 2){
                men.push({
                    text: this.lang.accounting, to: "/finance/coa",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 7
                })
            }
            

            if(currentmenu == 8){
                gcolor = '#fff';
                fcolor = "#000"
            }else{
                 gcolor = '#000';
                fcolor = '#fff';
            }
            men.push({
                text: this.lang.envintory, to: "/inventory/vendors",
                color: fcolor, 
                bgcolor: gcolor,
                current: 8
            })  
            
            if(this.$store.state.licenseType.salarysys && (this.groupid == 1)){
                if(currentmenu == 9){
                    gcolor = '#fff';
                    fcolor = "#000"
                }else{
                     gcolor = '#000';
                    fcolor = '#fff';
                }
                men.push({
                    text: this.lang.employees_and_users, to: "/emps/salaries",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 9
                })
            }
            // if(this.groupid == 1 || this.groupid == 2 || this.groupid == 3){
                if(currentmenu == 10){
                    gcolor = '#fff';
                    fcolor = "#000"
                }else{
                     gcolor = '#000';
                    fcolor = '#fff';
                }
                men.push({
                    text: this.lang.reports, 
                    to: "/reports/vat-reports",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 10
                })
            // }

            if(this.groupid == 1){
                if(currentmenu == 11){
                    gcolor = '#fff';
                    fcolor = "#000"
                }else{
                     gcolor = '#000';
                    fcolor = '#fff';
                }
                men.push({
                    text: this.lang.settings, 
                    to: "/settings/system-settings",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 11
                })
            }
            if(!this.$store.state.licenseType.fullversion){
                men = [];
                if(this.$store.state.licenseType.cars){
                    if(currentmenu == 1){
                        gcolor = '#fff';
                        fcolor = "#000"
                    }
                    if(this.lang.langname=="ar")
                        men.push({
                            text: this.$store.state.hometitle,
                            to: '/home',
                            color: fcolor, 
                            bgcolor: gcolor,
                            current: 1
                        })
                    else
                        men.push({
                            text: this.$store.state.hometitle_en,
                            to: '/home',
                            color: fcolor, 
                            bgcolor: gcolor,
                            current: 1
                        })
                }
                if(!this.$store.state.licenseType.crm){
                    if(currentmenu == 12){
                        gcolor = '#fff';
                        fcolor = "#000"
                    }else{
                        gcolor = '#000';
                        fcolor = '#fff';
                    }
                    men.push({
                        text: this.lang.sales_crm, to: "/clientList",
                        color: fcolor, 
                            bgcolor: gcolor,
                            current: 12
                    })
                }
                if(currentmenu == 8){
                    gcolor = '#fff';
                    fcolor = "#000"
                }else{
                    gcolor = '#000';
                    fcolor = '#fff';
                }
                men.push({
                    text: this.lang.envintory, to: "/inventory/products",
                    color: fcolor, 
                    bgcolor: gcolor,
                    current: 8
                })

                if(this.groupid == 1 || this.groupid == 2){
                    if(currentmenu == 10){
                        gcolor = '#fff';
                        fcolor = "#000"
                    }else{
                        gcolor = '#000';
                        fcolor = '#fff';
                    }
                    men.push({
                        text: this.lang.reports, 
                        to: "/reports/vatreports",
                        color: fcolor, 
                        bgcolor: gcolor,
                        current: 10
                    })
                }
            }
            return men;
        },
        groupid: function() {
            return this.$store.state.group_id;
        },
    }
}
</script>
<style>
.gbackground{
    position: relative;
    z-index: 10;
    background: rgb(215,177,0);
    background: linear-gradient(
    -45deg, rgba(215,177,0,1) 0%, 
    #d7b100 88%, rgba(0,0,0,1) 88%, 
    rgba(0,0,0,1) 89%, rgba(215,177,0,1) 89%, 
    rgba(215,177,0,1) 91%, rgba(0,0,0,1) 91%, 
    rgba(0,0,0,1) 92%, rgba(215,177,0,1) 92%, 
    rgba(215,177,0,1) 94%, rgba(0,0,0,1) 94%, 
    rgba(0,0,0,1) 95%, rgba(215,177,0,1) 95%, 
    rgba(215,177,0,1) 100%
    );
    padding:0px !important;
}
.logoutclass{
    color:#FFF;
    position: absolute;
    left:5px;
    top: 10px;
}
.clearMe{
    position: relative;
    background: rgb(215,177,0);
    direction:rtl;
    color:#FFF;
    padding:2px 10px 10px 5px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
.imglogo{
    position: absolute;
    top:2px;
    left:3px;
    z-index: 1000;
    height:85px;
    border: 2px solid #000;
}
td, th{
    border:1px solid #000 !important;
}
</style>